import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are a lot of guides and some top notch documentation on getting start with a Raspberry Pi. Here are the parts I used to get the Pi set up, and the steps that I followed.`}</p>
    <p>{`All of the links I provided are important and really thorough. Follow them carefully!`}</p>
    <p>{`requirements:`}</p>
    <ul>
      <li parentName="ul">{`Pi Zero W`}</li>
      <li parentName="ul">{`Micro SD card (8gb)`}</li>
      <li parentName="ul">{`SD Card reader`}</li>
      <li parentName="ul">{`Set of headers`}</li>
      <li parentName="ul">{`soldering iron`}</li>
      <li parentName="ul">{`solder`}</li>
      <li parentName="ul">{`mini hdmi adapter`}</li>
      <li parentName="ul">{`micro usb to usb adapter`}</li>
      <li parentName="ul">{`keyboard`}</li>
      <li parentName="ul">{`display`}</li>
      <li parentName="ul">{`hdmi cable`}</li>
    </ul>
    <p>{`Steps:`}</p>
    <ul>
      <li parentName="ul">{`Solder the headers on`}</li>
      <li parentName="ul">{`Download OS `}<a parentName="li" {...{
          "href": "https://www.raspberrypi.org/downloads/raspberry-pi-os/"
        }}>{`https://www.raspberrypi.org/downloads/raspberry-pi-os/`}</a>{` (noobs)`}</li>
      <li parentName="ul">{`Follow directions to flash `}<a parentName="li" {...{
          "href": "https://www.raspberrypi.org/documentation/installation/installing-images/README.md"
        }}>{`https://www.raspberrypi.org/documentation/installation/installing-images/README.md`}</a></li>
      <li parentName="ul">{`Boot and login (user is pi, password is raspberry)`}</li>
      <li parentName="ul">{`Change password for pi user`}</li>
      <li parentName="ul">{`add new user (`}<a parentName="li" {...{
          "href": "https://www.raspberrypi.org/documentation/linux/usage/users.md"
        }}>{`https://www.raspberrypi.org/documentation/linux/usage/users.md`}</a>{`)`}
        <ul parentName="li">
          <li parentName="ul">{`give yourself sudo permissions`}</li>
          <li parentName="ul">{`add file to sudoers.d`}</li>
        </ul>
      </li>
      <li parentName="ul">{`run `}<inlineCode parentName="li">{`sudo raspi-config`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Set up network options`}</li>
          <li parentName="ul">{`(optional) set up boot options to boot to desktop`}</li>
          <li parentName="ul">{`Interfacing Options`}
            <ul parentName="li">
              <li parentName="ul">{`Enable SSH, SPI, I2C`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`reboot and login as your new user`}</li>
      <li parentName="ul">{`run `}<inlineCode parentName="li">{`sudo apt-get update`}</inlineCode>{` and `}<inlineCode parentName="li">{`sudo apt-get upgrade`}</inlineCode></li>
      <li parentName="ul">{`SSH in from your computer`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sudo apt-get install python3-pip`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sudo apt-get install -y python-smbus`}</inlineCode>{` `}<inlineCode parentName="li">{`sudo apt-get install -y i2c-tools`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://learn.adafruit.com/adafruits-raspberry-pi-lesson-4-gpio-setup/configuring-i2c"
        }}>{`https://learn.adafruit.com/adafruits-raspberry-pi-lesson-4-gpio-setup/configuring-i2c`}</a></li>
    </ul>
    <p>{`This final step on the Adafruit documentation for the ic2 setup is necessary for the next step, which is setting up the actual sensor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      